const baseUrl = 'https://pategou-back.pategou.com/'
// const baseUrl = 'https://pategou-back-test.pategou.com/'
// const baseUrl = 'http://localhost:8081/'
export const apiConfig = {
  apiMailNewsLetter: `${baseUrl}email/newsletter`,
  apiSendMail: `${baseUrl}email/sendmail`,
  apiPaymentCarte: `${baseUrl}api/payment/charge`,
  apiUrlEmailBooking: `${baseUrl}songbooks/resa`,
  apiUrlRegister: `${baseUrl}api/register`,
  apiUrlUpdate: `${baseUrl}api/update`,
  apiUrlLogin: `${baseUrl}api/login`,
  apiUrlResourcesUuid: `${baseUrl}resources/`,
  apiUrlBooking: `${baseUrl}bookings`,
  apiUrlBookingWithPackageSelected: `${baseUrl}bookings/withPackageSelected`,
  apiUrlBookingByUser: `${baseUrl}bookings/userBookings/`,
  apiUrlPriceResource: `${baseUrl}bookings/price`,
  apiUrlPriceTotalEnergyResource: `${baseUrl}bookings/price/total-energy`,
  apiUrlCancelOrDeleteBooking: `${baseUrl}bookings/`,
  apiUrlRegisterEntre: `${baseUrl}api/entre`,
  apiUrlUpdateEnter: `${baseUrl}api/entre/update`,
  apiUrlRegisterContact: `${baseUrl}api/contact`,
  apiUrlRegisterProspect: `${baseUrl}api/listprospect`,
  apiUrlRegisterPostProspect: `${baseUrl}api/prospect`,
  apiUrlCheckMail: `${baseUrl}api/checkmail`,
  apiUrlResetPassword: `${baseUrl}api/change`,
  apiUrlOpenSpaceResource: `${baseUrl}resources?category=OPEN_SPACE`,
  apiUrlPrivateOfficeResource: `${baseUrl}resources?category=PRIVATE_OFFICE`,
  apiUrlMeetingResource: `${baseUrl}resources?category=MEETING_ROOM`,
  apiUrlEventSpaceResource: `${baseUrl}resources?category=EVENT_SPACE`,
  apiUrlFreeSpaceResource: `${baseUrl}resources?category=FREE_SPACE`,
  apiUrlResources: `${baseUrl}resources?type=ROOMS`,
  apiUrlAdditionalServices: `${baseUrl}resources?type=SERVICES`,
  apiUrlBusyDates: `${baseUrl}resources/busyDate/`,
  apiUrlUser: `${baseUrl}user`,
  apiUrlResourcePackage: `${baseUrl}resources/packages/`,
  apiUrlResourcePackageSelected: `${baseUrl}bookings/packages_selected/`,
  apiUrlImagesResource: `${baseUrl}resources/images/`,
  apiUrlUploadFile: `${baseUrl}upload`,
  apiUrlDownloadFiles: `${baseUrl}files`,
  apiUrlLeads: `${baseUrl}leads`,
  apiUrlLeadComment: `${baseUrl}leads/comments`,
  apiUrlDeleteLeads: `${baseUrl}leads/`,
  apiUrlUpdateLeadComment: `${baseUrl}leads/update/`,
  apiUrlGetEnterprises: `${baseUrl}user/cliententreprise`,
  apiUrlGetProspectsEnterprise: `${baseUrl}user/cliententreprise/prospect`,
  apiUrlGetProspectIndividual: `${baseUrl}user/prospect`,
  apiUrlGetClientsEnterprise: `${baseUrl}user/cliententreprise/client`,
  apiUrlGetClientIndividual: `${baseUrl}user/client`,
  apiUrlOpenAccount: `${baseUrl}api/checkmail`,
  apiUrlPassParticularForClient: `${baseUrl}user/topassclient/`,
  apiUrlSetOrGetCompanyInvoice: `${baseUrl}invoice/company`,
  apiUrlSetOrGetIndividualInvoice: `${baseUrl}invoice/individual`,
  apiUrlSetArticleImage:`${baseUrl}api/images`,
  apiUrlArticle:`${baseUrl}api/articles`,
  apiUrlGetBonaprisoResource:`${baseUrl}resources/bonapriso/`

}
