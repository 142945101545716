import { useState, useEffect, lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import ProtectedRoutes from './routes/ProtectedRoutes' //Authenticated routes
import PublicRoute from './routes/PublicRoute'
import PrivateRoute from './routes/PrivateRoute'
import AdminRoute from './routes/AdminRoute'
import Loader from './components/Loader'
import { Contact } from './components/Contact'
import Navigation from './components/Navigation'
import { DataProvider } from './page/admin/Context'
import { UserProvider } from './page/admin/UserContext'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { NotFoundPage } from './page/NotFoundPage'

import { positions, transitions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const Validate_beta = lazy(() =>
  import('./page/admin/Validate_beta').then(({ Validate_beta }) => ({
    default: Validate_beta
  }))
)
const Coworking = lazy(() =>
  import('./page/servicesHub/Coworking').then(({ Coworking }) => ({
    default: Coworking
  }))
)

const ServicesCoworking = lazy(() =>
  import('./page/servicesHub/ServicesCoworking').then(
    ({ ServicesCoworking }) => ({ default: ServicesCoworking })
  )
)

const Bureau = lazy(() =>
  import('./page/espaceHub/Bureau').then(({ Bureau }) => ({ default: Bureau }))
)

const OpenSpace = lazy(() =>
  import('./page/espaceHub/OpenSpace').then(({ OpenSpace }) => ({
    default: OpenSpace
  }))
)

const EventSpace = lazy(() =>
  import('./page/espaceHub/EventSpace').then(({ EventSpace }) => ({
    default: EventSpace
  }))
)

const FreeSpace = lazy(() =>
  import('./page/espaceHub/FreeSpace').then(({ FreeSpace }) => ({
    default: FreeSpace
  }))
)

const SalleReunion = lazy(() =>
  import('./page/espaceHub/SalleReunion').then(({ SalleReunion }) => ({
    default: SalleReunion
  }))
)

const Domiciliation = lazy(() =>
  import('./page/servicesHub/Domiciliation').then(({ Domiciliation }) => ({
    default: Domiciliation
  }))
)

const Contacts = lazy(() =>
  import('./page/Contacts').then(({ Contacts }) => ({ default: Contacts }))
)

const NosSites = lazy(() =>
  import('./page/NosSites').then(({ NosSites }) => ({ default: NosSites }))
)

const Actualites = lazy(() =>
  import('./page/servicesHub/Actualites').then(({ Actualites }) => ({
    default: Actualites
  }))
)

const NewsDetails = lazy(() =>
  import('./page/NewsDetails').then(({ NewsDetails }) => ({
    default: NewsDetails
  }))
)

const Evenements = lazy(() =>
  import('./page/servicesHub/Evenements').then(({ Evenements }) => ({
    default: Evenements
  }))
)

const Conseil = lazy(() =>
  import('./page/servicesHub/Conseil').then(({ Conseil }) => ({
    default: Conseil
  }))
)

const Incubateur = lazy(() =>
  import('./page/servicesHub/Incubator').then(({ Incubator }) => ({
    default: Incubator
  }))
)

const Innovent = lazy(() =>
  import('./page/servicesHub/Innovent').then(({ Innovent }) => ({
    default: Innovent
  }))
)

const NosRessources = lazy(() =>
  import('./page/OurResources').then(({ OurResources }) => ({
    default: OurResources
  }))
)

const Login = lazy(() =>
  import('./components/Login').then(({ Login }) => ({ default: Login }))
)

const MainLoginForm = lazy(() =>
  import('./page/auth/MainLoginForm').then(({ MainLoginForm }) => ({
    default: MainLoginForm
  }))
)

const EnterpriseRegisterForm = lazy(() =>
  import('./components/EnterpriseRegisterForm').then(
    ({ EnterpriseRegisterForm }) => ({ default: EnterpriseRegisterForm })
  )
)

const PersonRegisterForm = lazy(() =>
  import('./components/PersonRegisterForm').then(({ PersonRegisterForm }) => ({
    default: PersonRegisterForm
  }))
)

const ForgetPassword = lazy(() =>
  import('./page/admin/ForgetPassword').then(({ ForgetPassword }) => ({
    default: ForgetPassword
  }))
)

const ResetPassword = lazy(() =>
  import('./page/admin/ResetPassword').then(({ ResetPassword }) => ({
    default: ResetPassword
  }))
)

const Home = lazy(() =>
  import('./page/Home').then(({ Home }) => ({ default: Home }))
)

const Layout = lazy(() =>
  import('./page/admin/dashboard/Layout').then(({ LabTabs }) => ({
    default: LabTabs
  }))
)

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true
})

const App = () => {
  const dataUser = JSON.parse(localStorage.getItem('dataUser'))
  let isAuthenticated
  let isAdmin = false
  if (dataUser?.length !== 0 && dataUser !== null) {
    isAuthenticated = true
    if (dataUser[0]?.role === 'ADMIN' || dataUser[0]?.role === 'ADMIN1') {
      isAdmin = true
    } else {
      isAdmin = false
    }
  } else {
    isAuthenticated = false
  }
  const [landingPageData, setLandingPageData] = useState({})

  const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
    transition: transitions.SCALE
  }

  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <DataProvider>
        <UserProvider>
          <AlertProvider template={AlertTemplate} {...options}>
            <div>
              <BrowserRouter>
                <Navigation />
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Redirect from='/' to='home' exact />
                    <PublicRoute path='/home'>
                      <Home />
                    </PublicRoute>
                    <PublicRoute path='/register'>
                      <MainLoginForm />
                    </PublicRoute>
                    <PublicRoute path='/login'>
                      <Login />
                    </PublicRoute>
                    <PublicRoute path='/register-as-enterprise'>
                      <EnterpriseRegisterForm />
                    </PublicRoute>
                    <PublicRoute path='/register-as-person'>
                      <PersonRegisterForm />
                    </PublicRoute>
                    <PublicRoute path='/forget'>
                      <ForgetPassword />
                    </PublicRoute>

                    <PublicRoute path='/reinitialiser-mot-de-passe'>
                      <ResetPassword />
                    </PublicRoute>

                    <PublicRoute path='/incubateur'>
                      <Incubateur />
                    </PublicRoute>

                    <PublicRoute path='/innovent'>
                      <Innovent />
                    </PublicRoute>

                    <PublicRoute path='/conseil'>
                      <Conseil />
                    </PublicRoute>

                    <PublicRoute path='/evenements'>
                      <Evenements />
                    </PublicRoute>

                    <PublicRoute path='/actualites'>
                      <Actualites />
                    </PublicRoute>
                    <PublicRoute path='/article-details/:id'>
                      <NewsDetails />
                    </PublicRoute>
                    <PublicRoute path='/contacts'>
                      <Contacts />
                    </PublicRoute>
                    <PublicRoute path='/nos-sites'>
                      <NosSites />
                    </PublicRoute>
                    <PublicRoute path='/nos-ressources'>
                      <NosRessources />
                    </PublicRoute>

                    <PublicRoute path='/domiciliation'>
                      <Domiciliation />
                    </PublicRoute>
                    <PublicRoute path='/event-space'>
                      <EventSpace />
                    </PublicRoute>

                    <PublicRoute path='/free-space'>
                      <FreeSpace />
                    </PublicRoute>
                    <PublicRoute path='/salles-de-reunion'>
                      <SalleReunion />
                    </PublicRoute>

                    <PublicRoute path='/open-space'>
                      <OpenSpace />
                    </PublicRoute>

                    <PublicRoute path='/bureaux'>
                      <Bureau />
                    </PublicRoute>

                    <PublicRoute path='/services-coworking'>
                      <ServicesCoworking
                        home={false}
                        paddingTop={'40px'}
                        marginTop={'40px'}
                        sectionTitle='section-title'
                      />
                    </PublicRoute>

                    <PublicRoute path='/validate-beta'>
                      <Validate_beta />
                    </PublicRoute>

                    <PublicRoute path='/espaces-coworking'>
                      <Coworking />
                    </PublicRoute>

                    <AdminRoute
                      path='/tableau-de-bord-admin'
                      isAuthenticated={isAdmin}
                    >
                      <Layout />
                    </AdminRoute>

                    <PrivateRoute path='/' isAuthenticated={isAuthenticated}>
                      <ProtectedRoutes />
                    </PrivateRoute>

                    <Route path='*'>
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </Suspense>
              </BrowserRouter>
            </div>
            <Contact data={landingPageData.Contact} />
          </AlertProvider>
        </UserProvider>
      </DataProvider>
    </div>
  )
}

export default App
